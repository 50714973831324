/* Variables de color si el tema es nulo o light */
html {
    --primary-color: rgba(235, 233, 233, 0.582);
    --secondary-color: #f2f6f7;
    --periodo-text-color: #007bff;
    --text-color:black;
    --bg-theme: white;
    --bg-text-green :green;
    --bg-text-red : red;
    --bg-modal : white;
    --bg-nav: #007bff;
    --bg-body: #fafafa;
    --bg-gray: #cdcdcd;
    --hr-color: rgba(0,0,0,.1);
}


/* Variables de color si el tema es dark */
html[data-theme="dark"] {
    --primary-color: #343a40;
    --secondary-color:#212529;
    --text-color:white;
    --periodo-text-color: white;
    --bg-theme: gray;
    --bg-text-green : #77e777;
    --bg-text-red : #ff3d3d;
    --bg-modal : #3f4147;
    --bg-nav: #495057;
    --bg-body: #1f1f1f;
    --bg-gray: #595858;
    --hr-color: white;
}
/* 
    base: #3F9CFF
    Principal: #007BFF
    Acentuar: #7FBDFF 
    contraste: #FF8300 
*/

html[data-theme="dark"] .settings-component .card-header{
    background: var(--bg-modal);
}

html[data-theme="dark"] .settings-component .card-body{
    background: var(--bg-body);
}

html[data-theme="dark"] .form-control{
    background: black;
    color:  #ededed;
}

.no-decorations{
    text-decoration: none !important;
}
hr{
    border-color:var(--hr-color);
}
body{
    height: 100vh;
    background: var(--bg-body);
    color: var(--text-color);
}

.bg-body{
    background: var(--bg-body);
}
nav{
    background: var(--bg-nav);
}

.bg-theme{
    background: var(--bg-theme);
}


.bg-modal-theme{
    background: var(--bg-modal);
}
.color-red{
    color: var(--bg-text-red);
}
.color-green{
    color: var(--bg-text-green);
}
.header{
    height: 44px;
}
.sidebar{
    transition: all 0.3s;
    font-size: 13px;
    /* width: 15%;
    min-width: 15%; */
    background: var(--primary-color);
    padding: 1em 1em 0 1em;
    /* display: inline-block; */
	/* vertical-align: top; */
    height: calc(100vh - 50px - 5px) !important;
    max-height: calc(100vh - 50px - 5px) !important;
    min-height: calc(100vh - 50px - 5px) !important;

    
}
.sidebar.collapse{
    min-width: 3em;
    max-width: 3em;
    
}
.sidebar.active {
    margin-left: -250px;
}
/* 
.sidebar:hover{
    overflow-y: auto;
} */

#periodo-wraper{
    overflow: hidden;
}
#periodo-wraper{
    overflow-y: auto;

}
#cuentas-wrapper{
    /* display: inline-block; */
    overflow: scroll;
    width: 100%;
    border: none;
    vertical-align: top;
    height: calc(100vh - 110px);
}
.main{
    font-size: 13px;
    height: calc(100vh - 55px);
    /* width: 85%; */
    padding: 1em 0 0 1em;
    
    display: inline-block;
    overflow: hidden;
    background: var(--secondary-color);
	vertical-align: top;
    color:var(--text-color);
    width: 100%;
    /* padding: 20px; */
    transition: all 0.3s;
}
.modal{
    opacity: 1 !important;
}

.text-theme{
    color:var(--text-color)
}


.add-periodo-link{
    text-align: center;
}

.periodo{
    cursor:pointer;
    padding-top:0.5em;
    padding-bottom:0.5em;
    display: block;
    padding-left: 5px;
    text-decoration: none;
    color:var(--periodo-text-color);
    
}
.periodo:hover{
    font-weight: bold;
    text-decoration: none;
    color:var(--periodo-text-color);
}
.periodo[aria-current="page"]{
    background: var(--bg-theme);
    font-weight:bold;
    text-decoration: none;
    border-left: 0.3em solid var(--periodo-text-color);
    color:var(--periodo-text-color);

}

.movimiento{
    font-size: 10.5pt;
    cursor: pointer;
    margin-bottom: 0.2em;
    width: 350px  !important;
    color: black !important;
}
.movimiento .card-body{
    padding: 0.1em 1em;
}
.columna{
    margin-right: 0.5em;
}

.movimiento-title{
    font-weight: bold;
    margin-bottom: 0.25em;
    width: 350px  !important;
    font-size: 11pt;
    color:black;
    padding: 0.5em 1em;
}


.movimiento-title .card-body{
    padding: 0.5em 1em;
}

.addMove{
    text-align:center;
    opacity: 0.5;
    font-size:"10.5pt";
}

.addMove:hover{
    cursor: pointer;
    opacity: 0.8;
}


.navbar-brand{
    font-size: 1rem;
}

.cursor-pointer{
    cursor: pointer;
}

.edit-icon{
    opacity: 0.60;
    margin-bottom: 5px;
    display: none;
}

.edit-icon:hover{
    cursor: pointer;
    opacity: 1;
}

*:hover>.edit-icon{
    display: inline-block;
}

.btn-delete-periodo{
    cursor: pointer;
    float: right;    
    opacity: 0;
    margin-right: 5px;
}

*:hover>.btn-delete-periodo{
    opacity: 0.9;
}
.dot-options{
    cursor: pointer;
    opacity: 0;
    color: var(--text-color);
    margin-right: 5px;
    margin-top: auto;
    margin-bottom: auto;
}

*:hover>.dot-options{
    opacity: 0.5;
}
.dot-options:hover{
    opacity: 1;
}

li[role="presentation"] a{
    text-decoration:none;
    color:black;
    font-size:12pt;
}

.newCuenta{
    margin-top: 2em;
    margin-right: 2em;
    padding: 1em;
    background: var(--bg-gray);
    border-radius: 15px;
    height:fit-content;
    opacity: 0.6;
    text-align: center;
    white-space: nowrap;   
}
.newCuenta:hover{
    opacity: 1;
    border: none;
    cursor: pointer;
    background: var(--bg-nav);
    color:white;
    font-weight: bold;
    
}


.link-delete-cuenta{
    display: inline-block;
    opacity: 0.7;
    font-weight: bold;
}
.link-delete-cuenta:hover{
    opacity: 1;
    cursor: pointer;
}

.credit-card{
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
.credit-card:hover{
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
}

.collapse-sidebar-button{
    height: 40px;
    width: 100%;
    
     display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.collapse-sidebar-button:hover{
    cursor: pointer;
    opacity: 1;
}


@media (max-width: 360px) { 
    .movimiento-title{
        width: 280px  !important;
    }   

    .movimiento{
        width: 280px  !important;
    }
}

@media (max-width: 575.98px) { 
    .main{
        width: 100%;
    }
}
@media (max-width: 767.98px) { 
    .main{
        width: 100%;
    }
    .dot-options{
        opacity: 0.5;
    }
    .edit-icon{
        display: inline-block;
    }
    .link-delete-cuenta{
        opacity: 1;
    }
}



.nunito{
    font-family: Nunito; 
    font-weight: bold;
    color: rgb(0, 123, 255);
}