@import url('https://rsms.me/inter/inter.css');

*{
    font-family: 'Inter', sans-serif;

}

a.link{
    font-size:0.9rem;
    font-weight:bold;
    opacity:0.8;
    text-decoration: none;
    cursor: pointer;
}

.cuenta-card{
    height: 45vh;
    width: 90%;
}
.template-sidebar{
    width: 40%;
    height: 90%;
    background: gray;
    position: absolute;
    bottom: -10%;
    left: -20%;
}

.template-periodo{
    width: 90%;
    margin: 1em auto;
    height: 25px;
    border-radius: 25px;
    background: white;
}

.img_template_browser{
    width: 90%;
}
.img_template_movimientos{
    width: 80%;
}

section.full-heigth{
    height:calc(100vh - 75px);
    display: flex;
    align-items: center;
}

.blue-gradient{
    background: #56CCF2;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2F80ED, #56CCF2);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2F80ED, #56CCF2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
}
section.mid-heigth{
    display: flex;
    padding-top: 75px;
    padding-bottom: 75px;
    align-items: center;
}
.gradient-2{

    background: #BE93C5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #7BC6CC,#7BC6CC, #BE93C5);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #7BC6CC,#7BC6CC, #BE93C5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
}
header{
    -webkit-box-shadow: 0px 4px 15px -1px rgba(0,0,0,0.22); 
    box-shadow: 0px 4px 15px -1px rgba(0,0,0,0.22);
}
.img-example{
    margin:0 auto;
    width: 90%;
    transform:  
    rotateY(0deg)
    rotateX(0deg) 
    ;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important
}

.gradient-light{
    background: #E0EAFC;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #CFDEF3, #E0EAFC);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #CFDEF3, #E0EAFC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.wraper-star-now{
    text-align: left;
}

@media (max-width: 767.98px) { 
    .wraper-star-now{
        text-align: center;
    }

    .img_template_browser{
        width: 80%;
    }
}




.btn-shadow{
    -webkit-box-shadow: 0px 4px 15px -1px rgba(0,0,0,0.22); 
    box-shadow: 0px 4px 15px -1px rgba(0,0,0,0.22);
}

.btn.btn-shadow:hover{
    box-shadow: 1px 4px 13px 0px rgba(0,0,0,0.39);
    -webkit-box-shadow: 1px 4px 13px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 1px 4px 13px 0px rgba(0,0,0,0.39);
}