.estadisticas-component{
    .title-page{
        color: black;
        text-decoration: none;
    }
    .title-page:hover{
        font-weight: bold;
    }
    
    .estadisticas-menu{
        .card{
            color:black;
            width: 19rem;
            font-size:11pt;
            cursor: pointer;
            
        }
        .card:hover{
            -webkit-box-shadow: 0px 2px 15px -4px rgba(92,92,92,1);
            -moz-box-shadow: 0px 2px 15px -4px rgba(92,92,92,1);
            box-shadow: 0px 2px 15px -4px rgba(92,92,92,1);
            
        }
    }


}